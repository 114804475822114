import Vue from 'vue';
import ElementUI from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import './style/style.scss';
import './icons';
import VueI18n from 'vue-i18n';
import zh from './i18n/config/zh';
import en from './i18n/config/en';
import locale from 'element-ui/lib/locale'
if (localStorage.getItem('locale') === 'en') {
  locale.use(en)
  document.title = 'login'
} else {
  locale.use(zh)
  document.title = '登录'
}
Vue.config.productionTip = false;

Vue.config.devtools = true;
Vue.use(ElementUI);
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh',
  messages: {
    zh,
    en,
  },
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
